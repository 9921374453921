/* src/CouponViewer.css */
.coupon-viewer {
    margin: 20px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  
  .coupon-viewer h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  
  .search-container {
   
    margin-bottom: 20px;
  }
  
  .search-container input[type='text'] {
    width: 300px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }
  
  .table-container {
    overflow-x: auto;
  }
  
  .coupon-viewer table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
  }
  
  .coupon-viewer th,
  .coupon-viewer td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: center;
  }
  
  .coupon-viewer th {
    background-color: #f4f4f4;
    color: #333;
  }
  
  .coupon-viewer tbody tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .coupon-viewer tbody tr:hover {
    background-color: #f1f1f1;
  }
  
  .pagination {
    margin-top: 20px;
    text-align: center;
  }
  
  .pagination button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 8px 16px;
    margin: 0 5px;
    cursor: pointer;
  }
  
  .pagination button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .pagination span {
    margin: 0 10px;
    font-weight: bold;
    font-size: 16px;
  }
  .date-filter {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
  }
  
  .date-filter label {
    font-weight: bold;
    margin-right: 5px;
  }
  
  .date-filter input {
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .date-filter input:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  }
  .pagination button:disabled {
    background-color: #cccccc;
  }
  
  .pagination .active {
    background-color: #0056b3;
  }
.print{
  margin-left: auto;
  display: block;
}
.print2{
  display: none;
}
.del1{
  display: block;
}
.del2{
  display: none;
}
  @media (max-width: 433px) {
.print{
  display: none;

}
.print2{
  display: block;
  margin-left: 6%;
}
.del2{
  display: block;
  white-space: nowrap;
 
}
.del1{
  display: none;
}
  }
