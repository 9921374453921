.byun {
    padding: 1.3em 3em;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 2.5px;
    font-weight: 500;
    color: #000;
    background-color: #edfcf1;
    border: none;
    border-radius: 45px !important;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
    
  }
  .win{
    text-align: center;
    padding: 10%;
  }
  .cou{
    font-size: 25px;
    text-align: center;
    padding-top: 10%;
  }
  .coup {
    font-size: 24px;
    margin-left: 37%;
    margin-top: -5%;
    opacity: 0;
    transform: translateY(50px) scale(0.8);
    transition: opacity 1s ease-in, transform 1s ease-in;
  }
  
  .coup.show {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
  .ScratchCard{
    background-color: #c5ffe8;
  }
  .coupon{
    font-size: 33px;
    margin-left: 42%;
    color: white;
    margin-top: -46%;
    font-weight: 700;
  }
  .coup2{
    font-size: 24px;
  margin-left: 26%;
  margin-top: -5%;
  padding: 5%;
  }
  .coupon2{
    font-size: 54px;
    margin-left: 34%;
    color: white;
    margin-top: -184px;
    font-weight: 700;
  }
  .coupon3 {
    font-size: 25px;
    margin-left: 42%;
    color: white;
    margin-top: -2%;
    font-weight: 700;
    line-height: 25px;
  }
  .underline{
    margin-left: 0% !important;
    margin-top: -2%;
    color: #f8d87b;
    height: 11px;
  }
  .coupon4{
    font-size: 16px;
  margin-left: 55%;
  margin-top: -2%;
  line-height: 41%;
  color: white;
  }
  .byun:hover {
    background-color: #23c483;
    box-shadow: 0px 15px 20px rgba(46, 229, 157, 0.4);
    color: #fff;
    transform: translateY(-7px);
  }
  
  .byun:active {
    transform: translateY(-1px);
  }

 /* Winner.css */
 .loader {
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 20px auto; /* Center the spinner */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


  .winnerimg{
    width: 100%;
    height: 215px;
    margin-top: -20%;
    margin-left: -16%;
  }
  
  @media (max-width: 972px) {
    .winnerimg{
    width: 149%;
    height: 215px;
    margin-top: -20%;
    margin-left: -42%;
    }
    .coupon2{
      font-size: 27px;
    }
    .coupon3{
      font-size: 20px;
    }
  }
  @media (max-width: 519px) {
    .coup{
      margin-left: 5%;
    }
  }