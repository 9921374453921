/* src/components/ReferAndEarn.css */
.refer-earn-title {
    font-family: 'Arial', sans-serif;
    font-weight: bold;
    color: #2c3e50;
  }
  
  .custom-search {
    border-radius: 20px;
    padding: 10px 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .refer-earn-card {
    border: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow: hidden;
  }
  
  .refer-earn-table {
    margin: 0;
    border-collapse: separate;
    border-spacing: 0;
    width: 100%;
  }
  
  .refer-earn-table th, .refer-earn-table td {
    border: none;
    padding: 15px;
    text-align: center;
  }
  
  .refer-earn-table thead {
    background-color: #f8f9fa;
  }
  
  .refer-earn-table tbody tr:nth-child(odd) {
    background-color: #f2f2f2;
  }
  
  .pagination {
    margin-top: 20px;
  }
  
  .pagination button:disabled {
    background-color: #cccccc;
  }
  
  .pagination .active {
    background-color: #0056b3;
  }
  