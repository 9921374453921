.footer {
    background-color: #000000;
    color: rgb(255, 255, 255);
    text-align: center;
    padding: 20px 0;
    position: absolute;
    width: 100%;
    /* bottom: 0; */
    margin-top: 3%;
  }
  
  .footer a {
    color: #fff;
    margin: 0 10px;
    text-decoration: none;
  }
  
  .footer a:hover {
    text-decoration: underline;
  }
  
  .footer-content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  