.card-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  
  .custom-card {
    border-radius: 15px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s, box-shadow 0.3s;
    width: 15rem;
    margin: 1.5rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 200px !important;
    
  }
  .card-body{
    margin-top: 50px;
  }
  
  .custom-card:hover {
    transform: scale(1.1);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3);
  }
  
  .card-title {
    font-size: 2rem;
    font-weight: bold;
    color: #540000 !important;
    font-family: "Anton SC", sans-serif;
  }

  
  .card-text {
    font-size: 1.5rem;
    color: #000000;
  }
  
  .mt-3 {
    margin-top: 1rem;
  }
  @keyframes scaleUp {
    to {
      transform: scale(1);
    }
  }

  * {
    /* Change your font family */
    font-family: sans-serif;
}

.content-table {
    border-collapse: collapse;
   
    font-size: 0.9em;
  width: 100%;
    border-radius: 5px 5px 0 0;
    overflow: hidden;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.content-table thead tr {
  background-color:#314057;
  color: #ffffff;
    text-align: left;
    font-weight: bold;
}

.content-table th,
.content-table td {
    padding: 12px 15px;
}

.content-table tbody tr {
    border-bottom: 1px solid #dddddd;
}

.content-table tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
}

.content-table tbody tr:last-of-type {
    border-bottom: 2px solid #009879;
}

.content-table tbody tr.active-row {
    font-weight: bold;
    color: #009879;
}

.coupon-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.coupon-item {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f8f9fa;
}

.coupon-number {
  font-weight: bold;
}
.container input {
  margin-bottom: 15px;
}

.pagination {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
}

.pagination li {
  margin: 0 5px;
}

.pagination .active {
  font-weight: bold;
}

.pagination button {
  border: none;
  background-color: #007bff;
  color: white;
  padding: 5px 10px;
  cursor: pointer;
}

.pagination button:hover {
  background-color: #0056b3;
}

.pagination button[disabled] {
  background-color: #6c757d;
  cursor: not-allowed;
}
.search-container2 {
  display: flex;
  justify-content: flex-end;
  padding: 75px;
}

.search-input2 {
  padding: 8px 12px;
  border: 1px solid #000000;
  border-radius: 4px;
  width: 300px;
  font-size: 16px;
}
.table-container{
  margin-top: -5%;
}

.hopetable{
  margin-top: 0%;
}